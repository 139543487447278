<template>
    <div class="dialog_section detail-content">
        <div class="detail_title" style="margin-top: 0.1rem">基础详情</div>
        <ul class="detail_form">
            <li>
                <span>设备号：</span>
                <b>{{ detailForm.deviceNumber }}</b>
            </li>
            <li>
                <span>绑定状态：</span>
                <b>{{ detailForm.bindStatus == 1 ? '已绑定' : '未绑定' }}</b>
            </li>
            <li>
                <span>激活状态：</span>
                <b>{{ detailForm.activationStatus == 1 ? '已激活' : '未激活' }}</b>
            </li>
            <li>
                <span>设备状态：</span>
                <b>{{ detailForm.onlineStatus == 1 ? '在线' : detailForm.onlineStatus == 2 ? '离线' : '--' }}</b>
            </li>
            <!-- <li>
						<span>插拔状态：</span>
						<b>{{detailForm.plugStatus==0?'插入':'拔出'}}</b>
					</li> -->
        </ul>
        <div class="detail_title">绑定车辆信息</div>
        <el-row class="detail_form el-row">
            <el-col :span="8">
                车牌号：<b>{{ detailForm.plateNo }}</b>
            </el-col>
            <el-col :span="8">
                车架号：<b>{{ detailForm.vin }}</b>
            </el-col>
            <el-col :span="8">
                设备绑定时间：<b>{{ detailForm.activationTime }}</b>
            </el-col>
        </el-row>
        <div class="detail_title">设备发放机构</div>
        <ul class="detail_form">
            <li>
                <b>{{ detailForm.sendDeviceOrg }}</b>
            </li>
        </ul>
        <div class="detail_title">行程数据</div>
        <el-row class="detail_form el-row" v-if="pathStr == '/devices/deviceList'">
            <el-col :span="8">
                行程总里程：<b>{{ detailForm.totalMileage }}km</b>
            </el-col>
            <el-col :span="8">
                行程总时长：<b>{{ detailForm.totalDuration }}h</b>
            </el-col>
            <el-col :span="8">
                总告警次数：<b>{{ detailForm.alarmCount }}次</b>
            </el-col>
        </el-row>
        <ul class="detail_form" v-else>
            <li>
                <span>行程数量：</span>
                <b>{{ detailForm.totalTripNum }}次</b>
            </li>
            <li>
                <span>行程总里程：</span>
                <b>{{ detailForm.totalMileage }}km</b>
            </li>
            <li>
                <span>行程总时长：</span>
                <b>{{ detailForm.totalDuration }}h</b>
            </li>
            <li>
                <span>总报警次数（三急一超）：</span>
                <b>{{ detailForm.totalAlarm ? Math.ceil(detailForm.totalAlarm / 1) : '' }}次</b>
            </li>
        </ul>
        <template v-if="pathStr != '/devices/deviceList'">
            <div class="detail_title">
                插拔记录
                <span>（仅展示最近十条记录）</span>
            </div>
            <div class="table_box">
                <el-table ref="multipleTable" :data="detailForm.alarmRecords" style="width: 100%">
                    <el-table-column label="序号" type="index" width="50"></el-table-column>
                    <el-table-column prop="alarmName" label="插拔操作"></el-table-column>
                    <el-table-column prop="time" label="插拔时间"></el-table-column>
                    <!-- <el-table-column prop="lon" label="参考经度（东经）"></el-table-column> -->
                    <!-- <el-table-column prop="lat" label="参考纬度（北纬）"></el-table-column> -->
                </el-table>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'DeviceDetail',
    props: {
        detailForm: Object
    },
    data() {
        return {
            tableData: [],
            pathStr: ''
        };
    },
    mounted() {
        this.pathStr = this.$route.path;
    },
    methods: {
        beforeClose(done) {
            this.$emit('beforeClose');
        }
    }
};
</script>
<style lang="less" scoped>
@import '../assets/css/table_white.css';
.detail-content {
    max-height: 490px;
}
.detail_form {
    .el-col {
        font-size: 0.18rem;
    }
}
.el-row {
    padding-bottom: 40px;
}
</style>