<template>
    <div class="content" v-cloak>
        <el-button class="back_btn" size="small" type="primary" @click="goBack">返回</el-button>
        <div class="page_title">告警列表</div>
        <div class="content_box">
            <div class="search_box" v-if="!isPlugDetail">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm">
                    <el-form-item label="车牌号:">
                        <el-input class="width_200" v-model="navForm.condition.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.condition.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="告警类型:">
                        <el-select class="width_160" v-model="navForm.condition.alarmType" placeholder="请选择" size="small" clearable filterable>
                            <el-option v-for="(item, index) in advanceAlarmOptions" :key="index" :label="item.desc" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="警情处理:">
                        <el-select v-model="navForm.condition.alarmStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已处理" :value="1"></el-option>
                            <el-option label="未处理" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="最小持续时长（s）:">
                        <el-input class="width_150" v-model="navForm.condition.minDuration" size="small" placeholder="请输入" clearable> </el-input>
                    </el-form-item> -->
                    <el-form-item label="最低速度（km/h）:">
                        <el-input class="width_150" v-model="navForm.condition.minSpeed" size="small" placeholder="请输入" clearable> </el-input>
                    </el-form-item>
                    <el-form-item label="起止时间:">
                        <el-date-picker class="form_time" v-model="queryTime" size="small" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="queryTimeChange"> </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="queryBtn" ref="queryBtnRef" type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <!--table表格部分-->
                <el-table
                    :class="{
                        maxHeight: isPlugDetail && tableHeight == 507,
                        minHeight: isPlugDetail && tableHeight == 342
                    }"
                    ref="multipleTable"
                    :data="tableData"
                    v-loading="loading"
                    @sort-change="sortChange"
                    :height="tableHeight"
                    style="width: 100%"
                >
                    <el-table-column prop="companyName" label="所属公司" width="120">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.companyName"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fleetName" label="所属车队" width="120"></el-table-column>
                    <el-table-column prop="plateNo" label="车牌号" width="100"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备号"></el-table-column>
                    <el-table-column prop="attachmentNum" label="附件">
                        <template slot-scope="scope">
                            <span class="clickTxt" v-if="scope.row.attachmentNum > 0" @click="showAttachment(scope.row)">{{ scope.row.attachmentNum }}</span>
                            <span v-else>{{ scope.row.attachmentNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="alarmTypeName" label="告警类型" width="150"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间" width="156"></el-table-column>
                    <!-- <el-table-column prop="endTime" label="结束时间" width="156"></el-table-column> -->
                    <el-table-column prop="alarmLevel" label="告警级别"></el-table-column>
                    <el-table-column prop="alarmSpeed" label="告警速度" width="100"></el-table-column>
                    <el-table-column prop="endLongitude" label="开始经纬度" width="100">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.startLongitude + '，' + scope.row.startLatitude" v-if="scope.row.startLongitude"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startLocation" label="地址" width="100">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.startLocation"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="alarmStatusName" label="警情处理状态" width="120"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.pageSize" :total="navForm.pageTotal">
                </el-pagination>
            </div>
            <!-- 附件弹窗 -->
            <el-dialog class="attachment_dialog" :visible.sync="dialogVisible" width="1000px" :modal-append-to-body="false">
                <div v-loading="previewLoading">
                    <div class="dialog_box" v-if="imgList.length > 0">
                        <div class="dialog_title">
                            <span>{{ attachmentRow.deviceNumber ? attachmentRow.deviceNumber : '--' }}</span>
                            <span class="warning_type">{{ attachmentRow.alarmTypeName ? attachmentRow.alarmTypeName : '--' }}</span>
                            <span>{{ attachmentRow.startTime ? attachmentRow.startTime : '--' }}</span>
                        </div>
                        <div class="attachment_box clearfix">
                            <div class="box_left fl" v-if="dialogVisible">
                                <img-deal ref="imgDeal" :url="currentItem.ossPath" v-if="currentItem.isImg"></img-deal>
                                <video :src="currentItem.ossPath" controls autoplay muted loop v-else></video>
                            </div>
                            <ul class="box_right fr">
                                <li :class="{ subsetActive: activeImgIndex == imgIndex, Img_li: 1 }" v-for="(imgItem, imgIndex) in imgList" :key="imgIndex" @click="previewItem(imgItem, imgIndex)">
                                    <img :src="imgItem.ossPath" v-if="imgItem.isImg" />
                                    <video :src="currentItem.ossPath" :poster="imgList[0].ossPath" muted v-else></video>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <el-empty description="暂无数据" v-else></el-empty>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            OSSClient: null,
            dialogVisible: false,
            previewLoading: true,
            currentItem: '',
            activeImgIndex: 0,
            imgList: [],
            isPlugDetail: false,
            attachmentRow: {
                device: '17716532123',
                totalTripsNum: '侧翻碰撞告警',
                startTime: '2022-11-07 17:09:23'
            },
            tableHeight: window.innerHeight > 760 ? 507 : 342,
            plubHeight: window.innerHeight > 760 ? 507 : 500,
            rid: '',
            loading: false,
            center: { lng: 116.404269, lat: 39.915378 },
            zoom: 18,
            BDmap: '',
            BMap: '',
            batchIdList: [], //车队
            advanceAlarmOptions: [],
            queryTime: null,
            navForm: {
                condition: {
                    search: null,
                    alarmStatus: null,
                    companyId: null,
                    fleetId: null,
                    alarmType: null,
                    minDuration: null,
                    minSpeed: null,
                    startTime: null,
                    endTime: null
                },
                orders: [],
                pageNo: 1,
                pageSize: 10,
                pageTotal: 0
            },
            downloadForm: {
                companyId: null,
                fleetId: null,
                lastPlugginTime: true,
                online: true,
                plugging: true,
                pullNum: true,
                pullNumDateRange: 'week',
                showHasTrip: true,
                showHasTripTimeStartParam: '',
                showHasTripTimeEndParams: ''
            },
            marker: { showFlag: false },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            companyList: [],
            currentUser: {
                userTeantType: 2
            },
            deviceQueryData: {}
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;

        if (this.$route.query && this.$route.query.item) {
            let queryData = JSON.parse(this.$route.query.item);
            this.navForm.condition.search = queryData.plateNo;
        }

        this.getData();
        this.getCompanyList();
        this.getBatchIdList();
        this.getCurrentUser();
        this.getAdvanceAlarmTypeEnum();
    },
    methods: {
        goBack() {
            let queryData = this.$route.query.queryData;
            this.$router.push({
                path: '/devices/deviceList',
                query: { queryData: queryData }
            });
        },
        // 初始化OSS
        initOSSClient(watchImgData) {
            this.instance.get('/ubi/api/aliyun/oss/access').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.OSSClient = new OSS({
                        accessKeyId: data.data.accessKeyId,
                        accessKeySecret: data.data.accessKeySecret,
                        stsToken: data.data.securityToken,
                        // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                        region: data.data.region,
                        bucket: data.data.bucket
                    });
                    // 初始化图片
                    // this.initImgUrl(watchImgData);
                } else {
                    console.log('oss初始化失败，正在重试！');
                    if (this.retryCount <= 200) {
                        setTimeout(() => {
                            this.retryCount++;
                            this.initOSSClient();
                        }, 200);
                    } else {
                        console.log('oss长时间初始化失败！');
                    }
                }
            });
        },
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = res.data;
                }
            });
        },
        getAdvanceAlarmTypeEnum() {
            this.instance.get('/ubi/api/enum/getAdvanceAlarmTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.advanceAlarmOptions = data.data;
                }
            });
        },
        //获取车队
        getBatchIdList(id) {
            this.navForm.condition.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        sortChange(column) {
            let tmpIndex = this.navForm.orders.findIndex((res) => {
                return res.column == column.prop;
            });
            let tmpOrderItem = {
                asc: column.order == 'ascending' ? true : false,
                column: column.prop
            };
            this.navForm.orders = [];
            this.navForm.orders.push(tmpOrderItem);
            column.order && this.navForm.orders.push(tmpOrderItem);
            this.getWarningData();
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage;
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize;
            }
            // 否则直接返回索引+1作为序号
            return index + 1;
        },
        getData() {
            this.isPlugDetail = this.$route.query.queryForm ? (this.$route.query.isPlugDetail == 'false' ? false : true) : false;
            if (this.$route.query.queryForm) {
                let routeForm = this.$route.query.queryForm;
                this.batchIdList = routeForm.tmpBatchIdList ? routeForm.tmpBatchIdList : this.batchIdList;
                this.navForm.condition.companyId = routeForm.companyId ? routeForm.companyId : this.navForm.condition.companyId;
                this.navForm.condition.fleetId = routeForm.fleetId ? routeForm.fleetId : this.navForm.condition.fleetId;
            }
            this.isPlugDetail = this.$route.query.isPlugDetail == 'true' ? true : false;
            this.getWarningData();
        },
        //点击查询
        searchTable() {
            this.navForm.pageNo = 1;
            this.getWarningData();
        },
        queryTimeChange() {
            if (this.queryTime) {
                this.navForm.condition.startTime = this.queryTime[0];
                this.navForm.condition.endTime = this.queryTime[1];
            } else {
                this.navForm.condition.startTime = null;
                this.navForm.condition.endTime = null;
            }
        },
        //获取tbody里的数据
        getWarningData() {
            this.loading = true;
            this.tableData = [];
            this.instance.post('/ubi/api/advance_alarm/page', this.navForm).then((res) => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.tableData = res.data.data.list;
                    this.navForm.pageTotal = res.data.data.total;
                } else {
                    this.navForm.pageTotal = 0;
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getWarningData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getWarningData();
        },
        showAttachment(attachmentRow) {
            this.initOSSClient();
            this.dialogVisible = true;
            this.attachmentRow = attachmentRow;
            this.getAttachments(attachmentRow);
        },
        previewItem(imgItem, imgIndex) {
            this.activeImgIndex = imgIndex;
            this.currentItem = imgItem;
        },
        getAttachments(rowItem) {
            this.previewLoading = true;
            this.imgList = [];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            this.instance.get('/ubi/api/advance_alarm/attachments', { params: { alarmId: rowItem.id } }).then((res) => {
                this.previewLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.imgList = data.data;
                    this.imgList.forEach((item) => {
                        item.fileType = item.fileName.split('.').pop();
                        item.isImg = fileTypeList.indexOf(item.fileType) != -1 ? true : false;
                    });
                    this.previewItem(this.imgList[0], 0);
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_white.css';
.content {
    position: relative;
    height: 100%;
}
.download_form .el-form-item {
    margin-bottom: 0;
}
.search_box {
    margin-bottom: 30px;
    .form_time {
        width: 350px !important;
        padding-right: 0;
    }
}
.search_box .el-form--inline .el-form-item {
    margin-bottom: 10px;
}
#container,
#conta {
    width: 100%;
    height: 500px;
}
#container /deep/ .BMap_pop {
    left: 264px !important;
    top: 90px !important;
}
#container /deep/ .BMap_pop p {
    line-height: 18px;
}
#container /deep/ .BMap_pop p:nth-child(3) {
    display: inline-block;
    width: 300px;
}
.content_box {
    height: 96%;
}
.content {
    position: relative;
    .back_btn {
        position: absolute;
        right: 0px;
        top: -5px;
    }
}
.maxHeight {
    height: 600px !important;
}
.minHeight {
    height: 455px !important;
}
.clickTxt {
    color: #409eff;
    cursor: pointer;
}

.attachment_dialog {
    /deep/ .el-dialog__body {
        padding-top: 0;
    }
    .dialog_title {
        span {
            color: #333;
            font-size: 16px;
        }
        .warning_type {
            margin: 0 30px;
        }
    }
    .attachment_box {
        height: 550px;
        .box_left {
            width: 780px;
            height: 550px;
            padding-right: 40px;
            border-right: 1px solid #8b8b8b;
            video {
                width: 100%;
                height: 100%;
                // border: 1px solid #ccc;
            }
        }
        .box_right {
            width: 170px;
            height: 550px;
            overflow-y: auto;
            // margin-right: 20px;
            text-align: center;
            padding-left: 25px;
            li {
                width: 120px;
                height: 120px;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            li:last-child {
                margin-bottom: 0;
            }
            .subsetActive {
                border: 2px solid red !important;
            }
        }
    }
}
</style>
